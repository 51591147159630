<template>
  <div class="body_">
    <my-fram>
      <div slot="default" class="content">
       <div class="top">
         <div class="top_left" @>当前位置：缴费记录</div>
         <div class="top_right">
           <div class="button" @click="goQuery">返回</div>
         </div>
       </div>
       <div class="foot">
         <div class="head">
           <el-row class="elrow" justify="center">
             <el-col :span="3" class="elcol">缴费房屋</el-col>
             <el-col :span="3" class="elcol">类型</el-col>
             <el-col :span="3" class="elcol">姓名</el-col>
             <el-col :span="3" class="elcol">实收金额</el-col>
             <el-col :span="3" class="elcol">缴存银行</el-col>
             <el-col :span="3" class="elcol">缴存渠道</el-col>
             <el-col :span="4" class="elcol">缴费时间</el-col>
             <el-col :span="3" class="elcol">操作</el-col>
           </el-row>
         </div>
         <div class="one">
           <el-row justify="center"  class="ct" v-for="(item,index) in tableData">
             <el-col :span="3" class="elcol">{{item.villageName}}-{{item.houseNum}}</el-col>
             <el-col :span="3" class="elcol">{{item.orderStatus=='1'?'首交':'补缴'}}</el-col>
             <el-col :span="3" class="elcol">{{item.ownerName}}</el-col>
             <el-col :span="3" class="elcol">{{item.orderAmt}}</el-col>
             <el-col :span="3" class="elcol">{{item.bankName}}</el-col>
             <el-col :span="3" class="elcol">{{item.channelType|mlgb}}</el-col>
             <el-col :span="4" class="elcol">{{item.payTime}}</el-col>
             <el-col :span="3"  class="elcol" style="text-align: center">
               <el-button  type="text" @click="pzdy(item)"><span class="bdpz">凭证补打</span></el-button>

           <el-dialog
                 :visible.sync="dialogVisiblepdf"
                 :show-close="true"
                 :close-on-click-modal="false"
                 style="width: 100%"
                 >
                    <pdf
                     ref="pdf"
                     :src="pzsrc"
                     style="display: inline-block; width: 90%;margin: 0 auto;"
                     >
                   </pdf>
                 <el-button @click="dialogVisiblepdf = false">退 出</el-button>
                 <el-button type="primary" @click="goPrint()">打 印</el-button>
               </el-dialog>
             </el-col>
           </el-row>
         </div>
       </div>
      </div>
    </my-fram>
  </div>
</template>

<script>
import MyFram from '@/components/myFram';
import  pdf from  'vue-pdf';
import printJS from 'print-js'
export default {
  name: 'PayRecord',
  components: { MyFram,pdf,printJS },
  data () {
    return {
      dialogVisible: false,
      dialogVisiblepdf:false,
      url:this.$Config.base_server,
      houseId:this.$route.query.houseId,
      tableData:[
        // {
        //   villageName:'瑞德摩尔',
        //   houseNum:'101室',
        //   orderStatus:'1',
        //   ownerName:'独孤求败',
        //   orderAmt:'5455',
        //   bankName:'瑞士银行',
        //   channelType:2,
        //   payTime:'2022-03-04 00:08:06'
        // },
      ],
      pzrow:{},
      currentTime:'',
      pzsrc:'',

    }
  },
  mounted() {
    var idCard= this.$utils.getIdcard();
    if (idCard==""){
      this.$alert("请将身份证放识别区","消息提示");
      return false;
    }
    this.$axios.post(this.url+'/QuerypayOrderByOrderNo?houseId='+this.houseId+"&stat="+3).then((res)=>{
      if (res.data.resp_code=='0'){
        this.tableData=res.data.datas;
      }else{
        this.$message.error('对不起,查询出错了');
      }
    })
  },

  filters:{
    mlgb(value){
      if (value==2){
        return '掌银';
       }
      if (value==1){
        return '柜面线上';
      }
      if (value==8){
        return '微信支付';
      }
      if (value==9){
        return '柜面线下';
      }
      if (value==10){
        return '掌银扫码';
      }
    }
  },


  methods: {
    goQuery () {
      this.$router.push({ path: '/query' })
    },

    pzdy(row){
      const  that=this;
      /*this.pzrow=row;
      this.getTime();*/
      that.pzsrc=this.url+'/Querypz?orderNo='+row.orderNo;
      that.dialogVisiblepdf=true;
    },
    goPrint(){
      printJS({
        printable: this.pzsrc, // 要打印的id
        type: 'pdf',
        style: '@page{size:auto;margin: 0 auto;}' //去除页眉页脚
      })
    },
    getTime(){
      const  that=this;
      const yy = new Date().getFullYear()
      const mm = (new Date().getMonth() + 1) < 10 ? ('0' + (new Date().getMonth() + 1)) : (new Date().getMonth() + 1)
      const dd = new Date().getDate() < 10 ? ('0' + new Date().getDate()) : new Date().getDate()
      that.currentTime=yy + '年' + mm + '月' + dd + '日 ';
    },

    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => {});
    }
  }
}
</script>

<style scoped  lang="stylus">
.body_{
  height: 100%;
  width: 100%;
}
.xq{
  background-image: linear-gradient(180deg, #E0E7FF 0%, #BFCFFF 100%);
  border: 0.0625rem solid #FFFFFF;
  border-radius: 0.25rem
  margin-right 0.625rem
  padding 0.25rem 0.625rem
  color: #162E85;
}
.bdpz{
  background #019B84;
  border-radius: 0.25rem;
  margin-right 0.625rem
  color: #fff;
  padding 0.25rem 0.625rem
  font-size 1.2rem
}
.content{
  height 100%
  width 100%
  display: flex;
  flex-direction: column;
  .top{
    width 100%
    height 15%
    display flex
    flex-direction row
    .top_left{
      width 65%
      line-height 100%
      font-size 1.725rem
      display flex
      flex-direction column
      justify-content center
      justify-items center
      color #FFFFFF
    }
    .top_right{
      width 35%
      line-height 100%
      font-size 1.725rem
      display flex
      flex-direction row
      justify-content right
      align-items center
      .button{
        width 5rem
        height 2.5rem
        line-height 2.4rem
        text-align center
        background-image linear-gradient(to bottom, #BDE4DD,#06B198)
        border-radius: .3rem;
        font-size 1.2rem
        color #fff
        margin-right 1rem
        cursor pointer
      }
    }
  }
  .foot{
    width 100%
    height 85%
    .head{
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
      border-left: 1px solid #82D5FF80 ;
      border-top: 1px solid #82D5FF80 ;
      border-right: 1px solid #82D5FF80 ;
      height 12%;
      background #019B84
      font-weight: 600;
      font-size: 1.2rem;
      color: #FFFFFF;
      .elrow{
        height 100%
        display flex
        flex-direction row
        align-items center
        justify-content center
        .elcol{
          text-align center
        }
      }
    }
    .one{
      width: 100%;
      height: 88%;
      background #DBEEEC
      display flex
      flex-direction column
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      border-left: 1px solid #82D5FF80 ;
      border-bottom: 1px solid #82D5FF80 ;
      border-right: 1px solid #82D5FF80 ;
      .ct{
        height calc(90% / 7)
        font-size: 1.2rem;
        color: #333333;
        display flex
        flex-direction row
        align-items center
        border-bottom 1px solid #6d979f
        .ct_2{
          color: #FFF0CA;
        }
        .ct_3{
          color: #FFFFFF;
          opacity 0.6
        }
       /deep/ .elcol.el-col {
          text-align: center;
        }
      }
    }
  }
}

  /deep/.el-dialog__title{
    font-size 14px
  }
 /deep/ .el-dialog__header{
   color #FFFFFF
  padding-left 0px
}
/deep/ .el-dialog__body{
     padding-top 0px
  .kpr{
    display inline-block
    text-align left
    padding-left 180px
    padding-top 5px
  }
  .fh{
    display inline-block
    text-align right
    padding-left 150px
  }
   }
  /deep/ .el-dialog__footer{
    text-align center
    padding-top 0px
  }


/*
/deep/ .el-descriptions-item__label{
    color black
    background-color #FFFFFF

  }
/deep/ .el-descriptions-item__content{
  border 1px solid #000000
}*/



.bigtable {
  text-align: center;
  /* 合并边框 */
  border-collapse: collapse;
  /* 边框颜色 */
  border-color: black;
  /* 边框宽度 */
  border-width: 1px;
  /* 设置边框样式是实线 */
  border-style: solid;
  font-family: PingFangSC-Medium;
}
/* 设置行高 */
tr {
  height: 35px;
  border-color: black;
  border-width: 2px;
  border-style: solid;
  white-space: pre-wrap
}

/* 设置单元格的样式 */
td {
  border-color: black;
  border-width: 1px;
  border-style: solid;
  white-space: pre-wrap
}

.pdf-viewer {
  position: absolute;
  left: 0px;
  right: 0;
  top: 40px;
  bottom: 0;
  overflow: auto;
  padding: 10px 20px;
}
</style>
